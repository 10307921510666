const i18n = {
  'reviewPage': {
    'field': {
      'name': '姓名：',
      'gender': '性別：',
      'age': '年齡：',
      'job': '職業：',
      'tags': '標籤：',
      'interest': '我的興趣：',
      'skill': '我擅長：',
      'wantingToLearn': '我想學習：',
    },
  }
};
export default i18n;
