const i18n = {
  'button': {
    'cancel': '取消',
    'ok': '套用',
    'save': '儲存',
    'submit': '送出',
    'continue': '繼續',
    'back': '返回主頁面'
  }
};
export default i18n;
