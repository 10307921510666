const i18n = {
  'sideMenu': {
    'profile': '個人資料',
    'suggestion': '推薦',
    'notification': '通知',
    'matched': '已通過',
    'setting': '設定',
    'logout': '登出'
  }
};
export default i18n;
