import React from 'react';

const DeleteAccount = () => {
  return (
    <div className="container">
      <div style={{ marginBottom: '20px' }}>空白頁面，還沒作好</div>
    </div>
  );
};

export default DeleteAccount;
